<script setup lang="ts">
import { ref } from "vue"
import { removePaymentMethod, setDefaultPaymentMethod } from "@http/FacebookAdClients"
import { capitalize } from "@utils"
import type { PaymentMethod } from "@models/PaymentMethod"

const props = defineProps<{
  facebookAdClientId: number
  paymentMethod: PaymentMethod
  isDefault: boolean
  allowRemoval: boolean
}>()

const emit = defineEmits<{
  (e: 'removePaymentMethod', stripePaymentMethodId: string): void
  (e: 'refreshPaymentMethods'): void
}>()

const disableButtons = ref<boolean>(false)

async function removePaymentMethodHandler(stripePaymentMethodId: string) {
  if (props.allowRemoval) {
    disableButtons.value = true
    await removePaymentMethod({ facebookAdClientId: props.facebookAdClientId, stripePaymentMethodId })
    emit('removePaymentMethod', stripePaymentMethodId)
  } else {
    console.error('Cannot remove the last payment method')
  }
  disableButtons.value = false
}

async function setDefaultPaymentMethodHandler(stripePaymentMethodId: string) {
  disableButtons.value = true
  await setDefaultPaymentMethod({ facebookAdClientId: props.facebookAdClientId, stripePaymentMethodId })
  emit('refreshPaymentMethods')
  disableButtons.value = false
}

const cap = capitalize
</script>

<template>
  <div class="flex justify-between items-center w-full bg-white pl-4 pr-2 py-2 rounded-md shadow">
    <div>
      <span class="block font-medium text-gray-900">{{ cap(paymentMethod.card.brand) }} **** **** **** {{ paymentMethod.card.last4 }}</span>
    </div>
    <div class="flex items-ccenter space-x-2">
      
      <span v-if="paymentMethod.expired" class="block text-red-600">Expired</span>
      <span v-else-if="paymentMethod.soonToExpire" class="block text-yellow-600">Soon to Expire</span>
      
      <button v-if="!paymentMethod.defaultCard && !paymentMethod.expired" @click="setDefaultPaymentMethodHandler(paymentMethod.id!)" :disabled="disableButtons" class="bg-blue-600 text-white px-3 py-0.5 rounded-md hover:bg-blue-700 disabled:bg-blue-200">Set as Default</button>
      <span v-else class="text-green-700">Default</span>

      <button v-if="!paymentMethod.defaultCard" @click="removePaymentMethodHandler(paymentMethod.id!)" :disabled="disableButtons" class="text-gray-600 px-0.5 py-0.5 rounded-md hover:bg-gray-100 disabled:bg-gray-100 disabled:text-gray-400">
        <svg class="size-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </div>
</template>