import http from '@http/apiProvider'
import type { FacebookAdClient } from '@models/FacebookAdClient'
import type { PaymentMethod } from '@models/PaymentMethod'

export type IUpdateFacebookAdClient = {
  sendMonthlyUpdateEmailTo: string
}

export type IUpdateFacebookAdClientResponse = {
  status: string
  message: string
}

export async function updateFacebookAdClientSendToEmails(params: IUpdateFacebookAdClient) {
  const response = await http.put(`/facebook_ad_clients/send_to_emails.json`, { params })
  return response.data as IUpdateFacebookAdClientResponse
}


export type IListPaymentMethodsParams = {
  facebookAdClientId: number
}

export type IListPaymentMethodsResponse = {
  data: PaymentMethod[];
};

export type IAttachPaymentMethodToFacebookAdClientParams = {
  stripePaymentMethodId: string
  facebookAdClientId: number
}

export type IAttachPaymentMethodToFacebookAdClientResponse = {

}

export type IRemovePaymentMethodParams = {
  stripePaymentMethodId: string
  facebookAdClientId: number
}

export type IRemovePaymentMethodResponse = {
  status: string
  message: string
}

export type ISetDefaultPaymentMethodParams = {
  stripePaymentMethodId: string
  facebookAdClientId: number
}

export type ISetDefaultPaymentMethodResponse = {
  status: string
  message: string
}

export type IListResponse = {
  data: FacebookAdClient[]
}

export async function list() {
  const response = await http.get(`/facebook_ad_clients/list.json`)
  return response.data as IListResponse
}

export async function listPaymentMethods(params: IListPaymentMethodsParams) {
  const response = await http.get(`/facebook_ad_clients/${params.facebookAdClientId}/list_payment_methods.json`)

  return response.data as IListPaymentMethodsResponse
}


export async function attachPaymentMethodToFacebookAdClient (params: IAttachPaymentMethodToFacebookAdClientParams) {
  const response = await http.patch(`/facebook_ad_clients/${params.facebookAdClientId}/attach_payment_method.json`, {
    stripePaymentMethodId: params.stripePaymentMethodId,
  })
  return response.data as IUpdateFacebookAdClientResponse
}

export async function removePaymentMethod(params: IRemovePaymentMethodParams) {
  const response = await http.patch(`/facebook_ad_clients/${params.facebookAdClientId}/remove_payment_method.json`, {
    stripePaymentMethodId: params.stripePaymentMethodId,
  })
  return response.data as IRemovePaymentMethodResponse
}

export async function setDefaultPaymentMethod(params: ISetDefaultPaymentMethodParams) {
  const response = await http.patch(`/facebook_ad_clients/${params.facebookAdClientId}/set_default_payment_method.json`, {
    stripePaymentMethodId: params.stripePaymentMethodId,
  })
  return response.data as ISetDefaultPaymentMethodResponse
}