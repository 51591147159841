<script setup lang="ts">
  import { computed, ref } from 'vue'
  import axios from '@http/apiProvider'

  const props = defineProps<{
    sendToEmail?: string
    facebookAdClientId: number
    organizationId: number
  }>()

  const monthName = ref<string>()

  const impressions = ref<number>()
  const engagements = ref<number>()
  const reach = ref<number>()
  const plays = ref<number>()
  const clicksToWebsite = ref<number>()
  const cpc = ref<number>()

  const monthImpressions = ref<number>()
  const monthEngagements = ref<number>()
  const monthReach = ref<number>()
  const monthPlays = ref<number>()
  const monthClicksToWebsite = ref<number>()
  const monthCpc = ref<number>()

  const sending = ref<boolean>(false)
  const sent = ref<boolean>(false)

  const hasEmailAddress = !!props.sendToEmail

  const canSend = computed(() => {
    return hasEmailAddress &&
      monthName.value !== undefined &&
      // impressions.value !== undefined &&
      // engagements.value !== undefined &&
      // reach.value !== undefined &&
      // plays.value !== undefined &&
      // clicksToWebsite.value !== undefined &&
      // cpc.value !== undefined &&

      monthImpressions.value !== undefined &&
      monthEngagements.value !== undefined &&
      monthReach.value !== undefined &&
      monthPlays.value !== undefined &&
      monthClicksToWebsite.value !== undefined &&
      monthCpc.value !== undefined &&

      sending.value === false
  })

  async function sendEmail () {
    if (!canSend) {
      return false
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    sending.value = true
    await axios.post(`/outbound_emails/send_facebook_update_email`, {
      facebookAdClientId: props.facebookAdClientId,

      monthName: monthName.value,

      impressions: impressions.value,
      engagements: engagements.value,
      reach: reach.value,
      plays: plays.value,
      clicksToWebsite: clicksToWebsite.value,
      cpc: cpc.value,

      monthImpressions: monthImpressions.value,
      monthEngagements: monthEngagements.value,
      monthReach: monthReach.value,
      monthPlays: monthPlays.value,
      monthClicksToWebsite: monthClicksToWebsite.value,
      monthCpc: monthCpc.value,
    })

    sending.value = false
    sent.value = true
  }
</script>

<template>
  <div class="relative bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl overflow-hidden">
    <div :class="{ 'hidden opacity-0': !sent, 'opacity-100': sent }" class="absolute transition duration-300 ease-in opacity-0 flex items-center justify-center space-x-4 w-full h-full top-0 left-0 bg-gray-200/80">
      <div class="text-gray-700 text-2xl">Sent Update Email</div>
      <svg class="block w-12 h-12 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
    </div>

    <div class="px-4 py-6 sm:p-8">
      <div class="mb-3">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Manually Send Monthly Update Email</h2>
        <p class="-mt-1 text-sm leading-6 text-gray-600">This will send an email to the client with their update.</p>
      </div>
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="month-name" class="block text-sm font-medium leading-6 text-gray-900">Month Name</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="monthName" type="text" name="month-name" id="month-name" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <!-- <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="impressions" class="block text-sm font-medium leading-6 text-gray-900">Impressions</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="impressions" type="text" name="impressions" id="impressions" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="engagements" class="block text-sm font-medium leading-6 text-gray-900">Ad Engagement</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="engagements" type="text" name="engagements" id="engagements" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="reach" class="block text-sm font-medium leading-6 text-gray-900">Reach</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="reach" type="text" name="reach" id="reach" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="plays" class="block text-sm font-medium leading-6 text-gray-900">Video Plays</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="plays" type="text" name="plays" id="plays" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="clicks-to-website" class="block text-sm font-medium leading-6 text-gray-900">Clicks to Website</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="clicksToWebsite" type="text" name="clicks-to-website" id="clicks-to-website" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="cpc" class="block text-sm font-medium leading-6 text-gray-900">Cost Per Click</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="cpc" type="text" name="cpc" id="cpc" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div> -->

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="month-impressions" class="block text-sm font-medium leading-6 text-gray-900">Month Impressions</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="monthImpressions" type="text" name="month-impressions" id="month-impressions" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="month-engagements" class="block text-sm font-medium leading-6 text-gray-900">Month Ad Engagement</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="monthEngagements" type="text" name="month-engagements" id="month-engagements" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="month-reach" class="block text-sm font-medium leading-6 text-gray-900">Month Reach</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="monthReach" type="text" name="month-reach" id="month-reach" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="month-plays" class="block text-sm font-medium leading-6 text-gray-900">Month Video Plays</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="monthPlays" type="text" name="month-plays" id="month-plays" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="month-clicks-to-website" class="block text-sm font-medium leading-6 text-gray-900">Month Clicks to Website</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="monthClicksToWebsite" type="text" name="month-clicks-to-website" id="month-clicks-to-website" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>

        <div class="sm:col-span-1 flex flex-col justify-end">
          <label for="month-cpc" class="block text-sm font-medium leading-6 text-gray-900">Month Cost Per Click</label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input v-model="monthCpc" type="text" name="month-cpc" id="month-cpc" class="block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
      <template v-if="hasEmailAddress">
        <span class="text-gray-500 text-sm">Email will be sent to <b>{{ sendToEmail }}</b>.</span>
      </template>
      <template v-else>
        <span class="text-gray-500 text-sm">
          <div class="font-bold">No Email Address</div>
          <div>Visit the client's <a :href="`/admin/organizations/${organizationId}`" class="text-brand-teal hover:text-brand-orange">organization profile</a> to set an email.</div>
        </span>
      </template>
      <button @click="sendEmail" type="button" :disabled="!canSend" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-300 disabled:cursor-not-allowed">
        {{ sending ? 'Sending...' : 'Send' }}
      </button>
    </div>
  </div>
</template>