<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { listPaymentMethods, list } from '@http/FacebookAdClients';
import CollectPaymentMethod from './CollectPaymentMethod.vue';
import PaymentMethodVue from './PaymentMethod.vue'
import type { PaymentMethod } from '@models/PaymentMethod';
import type { FacebookAdClient } from '@models/FacebookAdClient';

const facebookAdClients = ref<FacebookAdClient[]>([]);
const selectedFacebookAdClientId = ref<number>();
const paymentMethods = ref<PaymentMethod[]>([]);
const showAddCardForm = ref(false);
const loading = ref(false);

async function loadPaymentMethods() {
  if (selectedFacebookAdClientId.value === undefined) {
    return;
  }

  loading.value = true;
  const listPaymentMethodsResponse = await listPaymentMethods({ facebookAdClientId: selectedFacebookAdClientId.value });
  paymentMethods.value = listPaymentMethodsResponse.data;
  loading.value = false;
}

async function loadFacebookAdClientList() {
  const response = await list();
  facebookAdClients.value = response.data;
  if (facebookAdClients.value.length === 1) {
    selectedFacebookAdClientId.value = facebookAdClients.value[0].id
  }
}

function handleSave() {
  loadPaymentMethods();
  showAddCardForm.value = false;
}

function handleCancel() {
  showAddCardForm.value = false;
}

function handleRemovePaymentMethod (paymentMethodId: string) {
  const index = paymentMethods.value.findIndex((method) => method.id === paymentMethodId)
  paymentMethods.value.splice(index, 1)
}

onMounted(() => {
  loadFacebookAdClientList();
});

watch(selectedFacebookAdClientId, () => {
  loadPaymentMethods();
});
</script>

<template>
  <div>
    <div class="mb-12" v-if="facebookAdClients.length > 1">
      <label for="facebookAdClientId" class="block text-sm font-medium text-gray-700">Select Business</label>
      <select id="facebookAdClientId" v-model="selectedFacebookAdClientId"
        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-orange focus:border-brand-orange sm:text-sm rounded-md">
        <option v-for="client in facebookAdClients" :key="client.id" :value="client.id">{{ client.businessNickname }}
        </option>
      </select>
    </div>
    <div v-if="selectedFacebookAdClientId" class="space-y-6">
      <div v-if="loading" class="flex justify-center items-center py-4">
        <span class="text-gray-500">Loading payment methods...</span>
      </div>
      <ul v-else role="list" class="space-y-4">
        <li v-for="method in paymentMethods" :key="method.id">
          <PaymentMethodVue
            :facebookAdClientId="selectedFacebookAdClientId"
            :paymentMethod="method"
            :isDefault="method.defaultCard"
            :allowRemoval="paymentMethods.length > 1"
            @removePaymentMethod="handleRemovePaymentMethod"
            @refreshPaymentMethods="loadPaymentMethods" />
        </li>
      </ul>

      <button type="button" v-if="!showAddCardForm && !loading" @click="showAddCardForm = true" class="relative flex justify-center items-center w-full rounded-lg border-2 border-dashed border-gray-300 p-4 text-center hover:border-gray-400">
        <svg class="h-10 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
        </svg>

        <span class="text-base font-semibold text-gray-500">Add New Card</span>
      </button>

      <CollectPaymentMethod v-if="showAddCardForm"
        :facebookAdClientId="selectedFacebookAdClientId"
        @save="handleSave"
        @cancel="handleCancel" />
    </div>
  </div>
</template>
