import { attachPaymentMethodToFacebookAdClient, listPaymentMethods } from "@http/FacebookAdClients"

import type { StripeClient } from "@services/StripeClient"
import type { StripeCardElement, CreatePaymentMethodData, PaymentMethod } from "@stripe/stripe-js"

type PaymentMethodOptions = {
  name: string
  phone: string
  address: {
    line1: string
    line2?: string
    city: string
    state: string
    zip: string
    country: string
  }
}

class StripePaymentMethods {
  private parent: StripeClient

  constructor (parent: StripeClient) {
    this.parent = parent
  }

  public async listPaymentMethods (facebookAdClientId: number) {
    return await listPaymentMethods({ facebookAdClientId })
  }

  public async createPaymentMethod (cardElement: StripeCardElement, options?: PaymentMethodOptions) {
    this.parent.requireInitialization()

    const config = {
      type: 'card',
      card: cardElement,
    } as CreatePaymentMethodData

    config['billing_details'] = {
      ...options?.name !== undefined && { name: options.name },
      ...options?.phone !== undefined && { phone: options.phone },
      ...options?.address !== undefined && {
        address: {
          line1: options.address.line1,
          line2: options.address.line2,
          city: options.address.city,
          postal_code: options.address.zip,
          country: options.address.country,
        }
      }
    }

    const { paymentMethod, error } = await this.parent.client.createPaymentMethod(config)
    if (error) {
      throw new Error(error.message)
    }

    return paymentMethod
  }

  public async attachPaymentMethodToFacebookAdClient (paymentMethod: PaymentMethod, facebookAdClientId: number) {
    return await attachPaymentMethodToFacebookAdClient({
      stripePaymentMethodId: paymentMethod.id,
      facebookAdClientId: facebookAdClientId,
    })
  }
}

export default StripePaymentMethods