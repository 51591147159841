const preloaderId = '__preloader'

let el: HTMLElement | undefined = undefined
let active = false

function getPreloaderElement () {
  if (el === undefined) {
    el = document.getElementById(preloaderId) || undefined
  }
  return el
}

export function hidePreloader () {
  console.log('hiding')
  active = false
  getPreloaderElement()?.classList.add('hidden')
}

export function showPreloader () {
  console.log('shwing')
  active = true
  getPreloaderElement()?.classList.remove('hidden')
}