import './application.scss'

// Import the Stimulus controllers
import '@frontend/stimulus'

// Check if the rails response is requesting the preloader to be shown
import { showPreloader } from '@services/preloader'
if (window.globals.loadWithPreloader) {
  showPreloader()
}

import { createApp } from 'vue'
import type Vue from 'vue'
import { createVfm } from 'vue-final-modal'
import 'vue-final-modal/style.css'

function registerGlobalComponents(app: Vue.App<Element>) {
  const a = app

  const vfm = createVfm()
  app.use(vfm).mount('#app')
  // .use(vfmPlugin)
  // .use(VueClickAway)
  // .component('tabs', Tabs)
  // .component('tab', Tab)
  return a
}

// Check for incoming flash messages and Toast them instead
import { toast } from '@frontend/toasts/ToastService'
if (window.flashMessages.notice) { toast.success('Success', window.flashMessages.notice) }
if (window.flashMessages.alert) { toast.error('Error', window.flashMessages.alert) }

const COMPONENT_MAP: Map<string, any> = new Map<string, any>()

// IMPORTS
import FacebookResultsTable from '@components/FacebookResultsTable.vue'
COMPONENT_MAP.set('FacebookResultsTable', FacebookResultsTable)
import LineChartPreview from '@components/LineChartPreview.vue'
COMPONENT_MAP.set('LineChartPreview', LineChartPreview)
import SendFacebookUpdateEmail from '@components/SendFacebookUpdateEmail.vue'
COMPONENT_MAP.set('SendFacebookUpdateEmail', SendFacebookUpdateEmail)
import OutboundEmails from '@components/OutboundEmails.vue'
COMPONENT_MAP.set('OutboundEmails', OutboundEmails)
import OverTime from '@components/facebook_ads/OverTime.vue'
COMPONENT_MAP.set('OverTime', OverTime)
import Scorecard from '@components/scorecard/Scorecard.vue'
COMPONENT_MAP.set('Scorecard', Scorecard)
import DashboardRoot from '@components/facebook_ads/dashboard/DashboardRoot.vue'
COMPONENT_MAP.set('DashboardRoot', DashboardRoot)
import AccountSettings from '@components/AccountSettings.vue'
COMPONENT_MAP.set('AccountSettings', AccountSettings)
import UsersPage from '@components/organizations/UsersPage.vue'
COMPONENT_MAP.set('UsersPage', UsersPage)


const toInstantiate = document.querySelectorAll('[data-vue-component]')
if (toInstantiate.length > 0) {
  toInstantiate.forEach(el => {
    const elAttrs = el.attributes as IIndexable
    const comp = COMPONENT_MAP.get(elAttrs['data-vue-component'].value)
    const props = elAttrs['data-props']?.value
    if (comp) {
      registerGlobalComponents(
        createApp(comp, props && JSON.parse(props))
      ).mount(el)
      el.attributes.removeNamedItem('data-vue-component')
      el.attributes.removeNamedItem('data-props')
    }
  })
}


import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
flatpickr(".datepicker", {})