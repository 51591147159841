import http from '@http/apiProvider'

import Business from '@models/Business'

export type IFetchBusinessParams = {
  businessId: number
}

export type IFetchBusinessResponse = Business

export async function fetchBusiness (params: IFetchBusinessParams) {
  const response = await http.get(`/businesses/${params.businessId}.json`)
  return new Business(response.data.data) as IFetchBusinessResponse
}
