<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs } from '@vuelidate/validators'
import { toast } from '@frontend/toasts/ToastService'
import { updateAccountDetails, changePasswordAPI } from '@http/User'
import { updateFacebookAdClientSendToEmails } from '@http/FacebookAdClients'
import PaymentMethods from './stripe/PaymentMethods.vue'

import type { IAccountDetailsParams } from '@http/User'

const props = defineProps<{
  firstName: string,
  lastName: string,
  email: string,
  sendToEmails: string,
  isOrgAdmin: boolean,
}>()

const accountModel: IAccountDetailsParams = reactive({
  firstName: '',
  lastName: '',
  email: '',
})

const passwordModel = reactive({
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
})

const facebookAdClientModel = reactive({
  sendMonthlyUpdateEmailTo: '',
})


const accountRules = computed(() => ({
  firstName: { required },
  email: { required, email }
}))

const passwordRules = computed(() => ({
  currentPassword: { required },
  newPassword: { required },
  confirmPassword: { required, sameAs: sameAs(passwordModel.newPassword) },
}));



// Regular expression for simple email validation
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmails = (emailsString: string) => {
  const emails = emailsString.split(',').map(email => email.trim());
  return emails.every(email => emailRegex.test(email));
}


const setupData = async () => {
  accountModel.firstName = props.firstName || '';
  accountModel.lastName = props.lastName || '';
  accountModel.email = props.email || '';
  facebookAdClientModel.sendMonthlyUpdateEmailTo = props.sendToEmails || '';
}

const onSubmitProfileDetials = async () => {
  v$.value.$touch();  // Touch all fields to trigger validation

  if (!v$.value.$invalid) {
    const result = await updateAccountDetails(accountModel)

    if (result.status === 'ok') {
      toast.success("Updated", "Successfully updated the profile")
    } else {
      toast.error("Error", "Unable to update the profile")
    }
  } else {
    toast.error("Error", "Unable to update the profile")
  }
}

const onSubmitPasswordChange = async () => {
  passwordV$.value.$touch();  // Touch all fields to trigger validation

  if (!passwordV$.value.$invalid) {
    try {
      const result = await changePasswordAPI(passwordModel);

      if (result.status === 'ok') {
        toast.success("Success", "Password successfully changed");
        window.location.href = '/users/sign_in'; // Redirect to the login page or any other page
      } else if (result.status === 'unauthorized') {
        toast.error("Error", result.message || "Current password is incorrect");
      } else if (result.status === 'unprocessable_entity') {
        const errors = result.errors.join(', ');
        toast.error("Error", `Unable to change password: ${errors}`);
      } else {
        throw new Error("Unexpected response from the server");
      }
    } catch (error) {
      toast.error("Error", "An error occurred while changing the password");
    }
  } else {
    toast.error("Error", "Please correct the errors in the form.");
  }
};

const onSubmitFacebookEmails = async () => {
  if (validateEmails(facebookAdClientModel.sendMonthlyUpdateEmailTo)) {
    const result = await updateFacebookAdClientSendToEmails(facebookAdClientModel)
    if (result.status === 'ok') {
      toast.success("Success", result.message);
    } else {
      toast.error("Error", result.message);
    }
  } else {
    toast.error("Error", "Invalid email format in the list");
  }
}

setupData();

const v$ = useVuelidate(accountRules, accountModel)
const passwordV$ = useVuelidate(passwordRules, passwordModel)
</script>

<template>
  <div>
    <div>
      <main>
        <!-- Settings forms -->
        <div class="divide-y divide-black/5 dark:divide-white/5">
          <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div>
              <h2 class="text-base font-semibold leading-7 dark:text-white">Personal Information</h2>
              <p class="mt-1 text-sm leading-6 text-gray-400">Use a valid email address where you can receive e-mail.
              </p>
            </div>

            <form @submit.prevent="onSubmitProfileDetials" class="md:col-span-2">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label for="first-name" class="block text-sm font-medium leading-6 dark:text-white">First name</label>
                  <div class="mt-2">
                    <input v-model="accountModel.firstName" type="text" name="first-name" id="first-name"
                      autocomplete="given-name" @blur="v$.firstName.$touch"
                      class="block w-full rounded-md bg-white/5 py-1.5 dark:text-white shadow-sm sm:text-sm sm:leading-6"
                      :class="{ 'border-red-500': v$.firstName.$error }" />
                  </div>
                  <span class="text-red-500" v-if="v$.firstName.$error">{{ v$.firstName.$errors[0].$message.toString()
                    }}</span>
                </div>

                <div class="sm:col-span-3">
                  <label for="last-name" class="block text-sm font-medium leading-6 dark:text-white">Last name</label>
                  <div class="mt-2">
                    <input v-model="accountModel.lastName" type="text" name="last-name" id="last-name"
                      autocomplete="family-name"
                      class="block w-full rounded-md bg-white/5 py-1.5 dark:text-white shadow-sm sm:text-sm sm:leading-6" />
                  </div>
                </div>

                <div class="col-span-full">
                  <label for="email" class="block text-sm font-medium leading-6 dark:text-white">Email address</label>
                  <div class="mt-2">
                    <input v-model="accountModel.email" id="email" name="email" type="email" autocomplete="email"
                      @blur="v$.email.$touch"
                      class="block w-full rounded-md bg-white/5 py-1.5 dark:text-white shadow-sm sm:text-sm sm:leading-6"
                      :class="{ 'border-red-500': v$.email.$error }" />
                  </div>
                  <span class="text-red-500" v-if="v$.email.$error">{{ v$.email.$errors[0].$message.toString()
                    }}</span>
                </div>
              </div>

              <div class="mt-8 flex justify-end sm:max-w-xl">
                <button type="submit" :disabled="v$.$invalid" class="rounded-md bg-brand-orange px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-orange-500">Save</button>
              </div>
            </form>
          </div>

          <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div>
              <h2 class="text-base font-semibold leading-7 dark:text-white">Change password</h2>
              <p class="mt-1 text-sm leading-6 text-gray-400">Update your password associated with your account.</p>
            </div>

            <form @submit.prevent="onSubmitPasswordChange" class="md:col-span-2">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div class="col-span-full">
                  <label for="current-password" class="block text-sm font-medium leading-6 dark:text-white">Current
                    Password</label>
                  <div class="mt-2">
                    <input v-model="passwordModel.currentPassword" type="password" name="current-password"
                      id="current-password" autocomplete="current-password"
                      class="block w-full rounded-md bg-white/5 py-1.5 dark:text-white shadow-sm sm:text-sm sm:leading-6"
                      :class="{ 'border-red-500': passwordV$.currentPassword.$error }" />
                  </div>
                  <span class="text-red-500" v-if="passwordV$.currentPassword.$error">Current password is
                    required.</span>
                </div>

                <div class="col-span-full">
                  <label for="new-password" class="block text-sm font-medium leading-6 dark:text-white">New Password</label>
                  <div class="mt-2">
                    <input v-model="passwordModel.newPassword" type="password" name="new-password" id="new-password"
                      autocomplete="new-password"
                      class="block w-full rounded-md bg-white/5 py-1.5 dark:text-white shadow-sm sm:text-sm sm:leading-6"
                      :class="{ 'border-red-500': passwordV$.newPassword.$error }" />
                  </div>
                  <span class="text-red-500" v-if="passwordV$.newPassword.$error">New password is required.</span>
                </div>

                <div class="col-span-full">
                  <label for="confirm-password" class="block text-sm font-medium leading-6 dark:text-white">Confirm New
                    Password</label>
                  <div class="mt-2">
                    <input v-model="passwordModel.confirmPassword" type="password" name="confirm-password"
                      id="confirm-password" autocomplete="new-password"
                      class="block w-full rounded-md bg-white/5 py-1.5 dark:text-white shadow-sm sm:text-sm sm:leading-6"
                      :class="{ 'border-red-500': passwordV$.confirmPassword.$error }" />
                  </div>
                  <span class="text-red-500" v-if="passwordV$.confirmPassword.$error">Passwords must match.</span>
                </div>
              </div>

              <div class="mt-8 flex justify-end sm:max-w-xl">
                <button type="submit" class="rounded-md bg-brand-orange dark:fill-white px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-orange-500">Save</button>
              </div>
            </form>
          </div>

          <!-- Facebook Email Notifications -->
          <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div>
              <h2 class="text-base font-semibold leading-7 dark:text-white">Facebook Email Notifications</h2>
              <p class="mt-1 text-sm leading-6 text-gray-400">Configure the email notifications for Facebook updates.
              </p>
            </div>

            <form class="md:col-span-2">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div class="col-span-full">
                  <label for="facebook-emails" class="block text-sm font-medium leading-6 dark:text-white">Notification
                    Emails</label>
                  <div class="mt-2">
                    <textarea v-model="facebookAdClientModel.sendMonthlyUpdateEmailTo" id="facebook-emails"
                      name="facebook-emails"
                      class="block w-full rounded-md bg-white/5 py-1.5 dark:text-white shadow-sm sm:text-sm sm:leading-6"
                      placeholder="Enter emails separated by commas"></textarea>
                  </div>
                </div>
              </div>

              <div class="mt-8 flex justify-end sm:max-w-xl">
                <button type="button" @click="onSubmitFacebookEmails"
                  class="rounded-md bg-brand-orange dark:fill-white px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-orange-500">
                  Save
                </button>
              </div>
            </form>
          </div>

          <div v-if="props.isOrgAdmin" class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div>
              <h2 class="text-base font-semibold leading-7 dark:text-white">Stripe Payment Method</h2>
              <p class="mt-1 text-sm leading-6 text-gray-400">Manage all payment methods on your account.</p>
            </div>

            <div class="md:col-span-2">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div class="col-span-full">
                  <PaymentMethods :facebookAdClientId="1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
@http/FacebookAdClients