<script setup lang="ts">
  import { ref } from 'vue'
  import { queryOutboundEmails } from '@http/OutboundEmails'
  import type OutboundEmail from '@models/OutboundEmail'

  const props = defineProps<{
    scopeType: string
    scopeId: number
  }>()

  const outboundEmails = ref<Array<OutboundEmail>>([])
  const fetching = ref<boolean>(false)

  async function fetchOutboundEmails () {
    if (fetching.value === true) {
      return false
    }

    fetching.value = true
    outboundEmails.value = await queryOutboundEmails({
      scopeType: props.scopeType,
      scopeId: props.scopeId,
    })
    fetching.value = false
  }

  fetchOutboundEmails()
</script>

<template>
  <div class="relative -mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

      <!-- Start Email Table -->
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Sent At</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Delivered At</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Opened At</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Clicked At</th>
            <!-- <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <span class="sr-only">Edit</span>
            </th> -->
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="email in outboundEmails" class="even:bg-gray-50">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{{ email.sentAtFormatted }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ email.typeFormatted }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ email.to }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ email.deliveredAtFormatted }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ email.openedAtFormatted }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ email.clickedAtFormatted }}</td>
            <!-- <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
              <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, Lindsay Walton</span></a>
            </td> -->
          </tr>
        </tbody>
      </table>
      <!-- End Email Table -->

      <!-- Start Empty State -->
      <div v-if="outboundEmails.length === 0" class="my-6 text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z" />
        </svg>
        <h3 class="mt-2 text-sm font-semibold text-gray-900">No outbound emails yet</h3>
        <p class="mt-1 text-sm text-gray-500">Who knows what will be sent next?</p>
      </div>
      <!-- End Empty State -->
    </div>
  </div>
</template>