<script setup lang="ts">
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
// @ts-ignore -- No types file exists for this lib
import DateRangePicker from 'vue3-daterange-picker'
// import 'vue3-daterange-picker/dist/vue3-daterange-picker.css'

import OverTime from '@components/facebook_ads/OverTime.vue'
import { queryFacebookStatsForDashboard } from '@http/FacebookStats'
import { showPreloader, hidePreloader } from '@services/preloader'
import { cumulativeSum } from '@utils'

import type { IQueryFacebookStatsForDashboardResponse } from '@http/FacebookStats'
import FacebookNamedAreaResults from '@components/facebook_ads/FacebookNamedAreaResults.vue' // Import the component
import { fetchBusiness } from '@http/Businesses'
import type Business from '@models/Business'

const props = defineProps<{
  facebookAdClientId: number
  businessId: number
}>()

type DateRange = {
  startDate: Date,
  endDate: Date,
}

const defaultStartDate = DateTime.now().minus({ days: 7 }).toJSDate()
const defaultEndDate = DateTime.now().toJSDate()
const dateRange = ref<DateRange>({
  startDate: defaultStartDate,
  endDate: defaultEndDate,
})

const business = ref<Business | undefined>(undefined)
async function getBusinessData () {
  business.value = await fetchBusiness({ businessId: props.businessId })
  console.log(business.value)
}
getBusinessData()

let dataKey = ref<string>(uuid())
const data = ref<IQueryFacebookStatsForDashboardResponse | undefined>(undefined)
async function fetchData() {
  showPreloader()
  data.value = await queryFacebookStatsForDashboard({
    facebookAdClientId: props.facebookAdClientId,
    startDate: DateTime.fromJSDate(dateRange.value.startDate).toFormat('yyyy-MM-dd'),
    endDate: DateTime.fromJSDate(dateRange.value.endDate).toFormat('yyyy-MM-dd'),
  })
  dataKey.value = uuid()
  hidePreloader()
}

const overTimeChartKey = ref<string>(uuid())
enum OverTimeChartMode {
  CUMULATIVE = 'cumulative',
  DAILY = 'daily',
}
const overTimeChartMode = ref<OverTimeChartMode>(OverTimeChartMode.CUMULATIVE)
const overTimeChartDatasetCumulative = computed(() => {
  if (data.value === undefined) {
    return undefined
  }

  const sourceData = data.value.datasets[graphMetric.value]
  // @ts-ignore -- Silencing the TS error because the data array may come back as strings
  // in some cases when the values are floating point. Axios doesn't seem to be able to parse them properly.
  const rollingSum: Array<number> = cumulativeSum(sourceData.data.map((d) => parseFloat(d)))
  return {
    dataset: {
      name: sourceData.name,
      data: rollingSum,
    },
    max: rollingSum[rollingSum.length - 1],
  }
})

const overTimeChartDataset = computed(() => {
  if (data.value === undefined) {
    return undefined
  }

  overTimeChartKey.value = uuid()

  if (overTimeChartMode.value === OverTimeChartMode.CUMULATIVE) {
    return overTimeChartDatasetCumulative.value
  } else if (overTimeChartMode.value === OverTimeChartMode.DAILY) {
    return {
      dataset: data.value!.datasets[graphMetric.value],
      max: data.value!.datasets[graphMetric.value].max,
    }
  }
})

enum GraphMetrics {
  IMPRESSIONS = 'impressions',
  SPEND = 'spend',
  CLICKS_TO_WEBSITE = 'clicksToWebsite',
  CLICKS = 'clicks',
}
const graphMetric = ref<GraphMetrics>(GraphMetrics.IMPRESSIONS)
fetchData()

function onDateSelect(dr: DateRange) {
  dateRange.value = dr
  fetchData()
}
</script>

<template>
  <div class="mx-auto max-w-screen-2xl">
    <!-- <%# p-4 md:p-6 2xl:p-10 %> -->

    <div class="flex justify-between">
      <div class="flex flex-col">
        <div class="font-bold text-2xl">{{ business?.nickname }} <span class="font-normal">Brand Awareness Ads</span></div>
        <div>Your target audience is experiencing your brand.</div>
      </div>

      <div class="flex flex-col">
        <div class="text-right text-sm text-gray-600">Select a date range to view.</div>
        <DateRangePicker :opens="'left'" ref="picker" :timePicker="false" :dateRange="dateRange" :autoApply="true"
          :appendToBody="true" @select="onDateSelect" :dark="true"></DateRangePicker>
      </div>
    </div>

    <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7">
      <!-- Card Item Start -->
      <div
        class="rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-dark-strokedark dark:bg-dark-boxdark">
        <div class="flex h-11 w-11 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
          <svg class="fill-brand-orange dark:fill-white w-7 h-7" width="22" height="16" viewBox="0 0 22 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 15.1156C4.19376 15.1156 0.825012 8.61876 0.687512 8.34376C0.584387 8.13751 0.584387 7.86251 0.687512 7.65626C0.825012 7.38126 4.19376 0.918762 11 0.918762C17.8063 0.918762 21.175 7.38126 21.3125 7.65626C21.4156 7.86251 21.4156 8.13751 21.3125 8.34376C21.175 8.61876 17.8063 15.1156 11 15.1156ZM2.26876 8.00001C3.02501 9.27189 5.98126 13.5688 11 13.5688C16.0188 13.5688 18.975 9.27189 19.7313 8.00001C18.975 6.72814 16.0188 2.43126 11 2.43126C5.98126 2.43126 3.02501 6.72814 2.26876 8.00001Z"
              fill="" />
            <path
              d="M11 10.9219C9.38438 10.9219 8.07812 9.61562 8.07812 8C8.07812 6.38438 9.38438 5.07812 11 5.07812C12.6156 5.07812 13.9219 6.38438 13.9219 8C13.9219 9.61562 12.6156 10.9219 11 10.9219ZM11 6.625C10.2437 6.625 9.625 7.24375 9.625 8C9.625 8.75625 10.2437 9.375 11 9.375C11.7563 9.375 12.375 8.75625 12.375 8C12.375 7.24375 11.7563 6.625 11 6.625Z"
              fill="" />
          </svg>
        </div>

        <div class="mt-4 flex items-end justify-between">
          <div>
            <template v-if="data !== undefined">
              <h4 class="text-xl font-bold text-black dark:text-white" :key="dataKey" data-controller="animated-number"
                data-animated-number-start-value="0" :data-animated-number-end-value="data.datasets.impressions.total"
                data-animated-number-duration-value="750" data-animated-number-format="number">0</h4>
            </template>
            <template v-else>
              <h4 class="text-xl font-bold text-black dark:text-white">-</h4>
            </template>
            <span class="text-sm font-medium">Total Impressions</span>
          </div>

          <!-- <span class="flex items-center gap-1 text-sm font-medium text-meta-3">
            0.43%
            <svg class="fill-meta-3" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z" fill="" />
            </svg>
          </span> -->
        </div>
      </div>
      <!-- Card Item End -->

      <!-- Card Item Start -->
      <div
        class="rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-dark-strokedark dark:bg-dark-boxdark">
        <div class="flex h-11 w-11 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
          <svg class="stroke-brand-orange fill-transparent dark:stroke-white w-7 h-7" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
          </svg>
        </div>

        <div class="mt-4 flex items-end justify-between">
          <div>
            <template v-if="data !== undefined">
              <h4 class="text-xl font-bold text-black dark:text-white" :key="dataKey" data-controller="animated-number"
                data-animated-number-start-value="0" :data-animated-number-end-value="data.datasets.clicks.total"
                data-animated-number-duration-value="750" data-animated-number-format="number">0</h4>
            </template>
            <template v-else>
              <h4 class="text-xl font-bold text-black dark:text-white">-</h4>
            </template>
            <span class="text-sm font-medium"># Clicks</span>
          </div>

          <!-- <span class="flex items-center gap-1 text-sm font-medium text-meta-3">
            4.35%
            <svg class="fill-meta-3" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z" fill="" />
            </svg>
          </span> -->
        </div>
      </div>
      <!-- Card Item End -->

      <!-- Card Item Start -->
      <div
        class="rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-dark-strokedark dark:bg-dark-boxdark">
        <div class="flex h-11 w-11 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
          <svg class="stroke-brand-orange fill-transparent dark:stroke-white w-7 h-7" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>

        <div class="mt-4 flex items-end justify-between">
          <div>
            <template v-if="data !== undefined && data.datasets.clicks.total > 0">
              <h4 class="text-xl font-bold text-black dark:text-white" :key="dataKey" data-controller="animated-number"
                data-animated-number-start-value="0"
                :data-animated-number-end-value="data.datasets.spend.total / data.datasets.clicks.total"
                data-animated-number-duration-value="750" data-animated-number-format="currency">0.00</h4>
            </template>
            <template v-else>
              <h4 class="text-xl font-bold text-black dark:text-white">-</h4>
            </template>
            <span class="text-sm font-medium">$ per Click</span>
          </div>

          <!-- <span class="flex items-center gap-1 text-sm font-medium text-meta-3">
            2.59%
            <svg class="fill-meta-3" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z" fill="" />
            </svg>
          </span> -->
        </div>
      </div>
      <!-- Card Item End -->

      <!-- Card Item Start -->
      <div
        class="rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-dark-strokedark dark:bg-dark-boxdark">
        <div class="flex h-11 w-11 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
          <svg class="w-6 h-6 stroke-brand-orange fill-transparent dark:stroke-white" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
          </svg>
        </div>

        <div class="mt-4 flex items-end justify-between">
          <div>
            <template v-if="data !== undefined">
              <h4 class="text-xl font-bold text-black dark:text-white" :key="dataKey" data-controller="animated-number"
                data-animated-number-start-value="0" :data-animated-number-end-value="data.datasets.spend.total"
                data-animated-number-duration-value="750" data-animated-number-format="currency">0.00</h4>
            </template>
            <template v-else>
              <h4 class="text-xl font-bold text-black dark:text-white">-</h4>
            </template>
            <span class="text-sm font-medium">Total Ad Spend</span>
          </div>

          <!-- <span class="flex items-center gap-1 text-sm font-medium text-meta-5">
            0.95%
            <svg class="fill-meta-5" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z" fill="" />
            </svg>
          </span> -->
        </div>
      </div>
      <!-- Card Item End -->
    </div>

    <div class="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7 2xl:gap-7">
      <!-- ====== Chart One Start -->
      <div class="col-span-12 grid grid-cols-12 rounded-sm border border-stroke bg-white px-5 pt-7 pb-5 shadow-default dark:border-dark-strokedark dark:bg-dark-boxdark sm:px-7">
        <div class="col-span-12 lg:col-span-8 xl:col-span-9">
          <div class="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
            <div class="flex w-full flex-wrap gap-3 sm:gap-5">
              <div class="flex min-w-[11.875rem]">
                <span
                  class="mt-1 mr-2 flex h-[1rem] w-full max-w-[1rem] items-center justify-center rounded-full border border-brand-orange">
                  <span class="block h-[0.625rem] w-full max-w-[0.625rem] rounded-full bg-brand-orange"></span>
                </span>
                <div class="w-full">
                  <p class="font-semibold text-brand-orange">{{ data?.datasets[graphMetric]?.name }}</p>
                  <!-- <p class="text-sm font-medium"><%= start_date.strftime('%b %e') %> - <%= end_date.strftime('%b %e') %></p> -->
                </div>
              </div>
              <!-- <%# <div class="flex min-w-[11.875rem]">
                <span
                  class="mt-1 mr-2 flex h-[1rem] w-full max-w-[1rem] items-center justify-center rounded-full border border-brand-teal">
                  <span class="block h-[0.625rem] w-full max-w-[0.625rem] rounded-full bg-brand-teal"></span>
                </span>
                <div class="w-full">
                  <p class="font-semibold text-brand-teal">Total Sales</p>
                  <p class="text-sm font-medium">dates</p>
                </div>
              </div> %> -->
            </div>
            <!-- <div class="flex w-full max-w-45 justify-end">
              <div class="inline-flex items-center rounded-md bg-whiter p-1.5 dark:bg-meta-4">
                <button
                  class="rounded bg-white py-1 px-3 text-xs font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-dark-boxdark dark:text-white dark:hover:bg-dark-boxdark">
                  Day
                </button>
                <button
                  class="rounded py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card dark:text-white dark:hover:bg-dark-boxdark">
                  Week
                </button>
                <button
                  class="rounded py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card dark:text-white dark:hover:bg-dark-boxdark">
                  Month
                </button>
              </div>
            </div> -->
          </div>
          <div>
            <OverTime v-if="overTimeChartDataset !== undefined && data !== undefined" :key="`${dataKey}-${overTimeChartKey}`" :labels="data!.labels" :dataset="overTimeChartDataset.dataset"
              :options="{ yMax: overTimeChartDataset.max }"></OverTime>
          </div>
        </div>
        <div class="flex flex-col gap-y-4 rounded-sm px-4 py-4 col-span-12 lg:col-span-4 xl:col-span-3">
          <div>
            <div class="flex items-center justify-between mb-1">
              <h2 class="text-sm font-bold leading-6 text-gray-900">Display Mode</h2>
            </div>

            <div class="flex gap-x-3">
              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': overTimeChartMode === OverTimeChartMode.CUMULATIVE, 'border-gray-300': overTimeChartMode !== OverTimeChartMode.CUMULATIVE }"
                class="relative flex flex-1 justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none ">
                <button @click="overTimeChartMode = OverTimeChartMode.CUMULATIVE">Cumulative</button>
              </label>

              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': overTimeChartMode === OverTimeChartMode.DAILY, 'border-gray-300': overTimeChartMode !== OverTimeChartMode.DAILY }"
                class="relative flex flex-1 justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none">
                <button @click="overTimeChartMode = OverTimeChartMode.DAILY">Daily</button>
              </label>
            </div>
          </div>

          <div>
            <div class="flex items-center justify-between mb-1">
              <h2 class="text-sm font-bold leading-6 text-gray-900">Metric</h2>
            </div>

            <div class="flex flex-col gap-y-3">
              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': graphMetric === GraphMetrics.IMPRESSIONS, 'border-gray-300': graphMetric !== GraphMetrics.IMPRESSIONS }"
                class="relative justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none ">
                <button @click="graphMetric = GraphMetrics.IMPRESSIONS">Impressions</button>
              </label>

              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': graphMetric === GraphMetrics.SPEND, 'border-gray-300': graphMetric !== GraphMetrics.SPEND }"
                class="relative justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none">
                <button @click="graphMetric = GraphMetrics.SPEND">Spend</button>
              </label>

              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': graphMetric === GraphMetrics.CLICKS_TO_WEBSITE, 'border-gray-300': graphMetric !== GraphMetrics.CLICKS_TO_WEBSITE }"
                class="relative justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none">
                <button @click="graphMetric = GraphMetrics.CLICKS_TO_WEBSITE">Clicks to Website</button>
              </label>

              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': graphMetric === GraphMetrics.CLICKS, 'border-gray-300': graphMetric !== GraphMetrics.CLICKS }"
                class="relative justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none">
                <button @click="graphMetric = GraphMetrics.CLICKS">All Clicks</button>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- ====== Chart Two Start -->
      <!-- <include src="./partials/chart-02.html" /> -->
      <!-- ====== Chart Two End -->

      <!-- ====== Chart Three Start -->
      <!-- <include src="./partials/chart-03.html" /> -->
      <!-- ====== Chart Three End -->

      <!-- ====== Map One Start -->
      <!-- <include src="./partials/map-01.html" /> -->
      <!-- ====== Map One End -->

      <!-- ====== Table One Start -->
      <!-- <div class="col-span-12 xl:col-span-8">
        <include src="./partials/table-01.html" />
      </div> -->
      <!-- ====== Table One End -->

    </div>
    <div
      class="rounded border mt-4 md:mt-6 2xl:mt-7 bg-white p-8 shadow-default dark:border-dark-strokedark dark:bg-dark-boxdark">
      <FacebookNamedAreaResults v-if="data !== undefined" :key="dataKey" :named-areas="data.datasets.namedAreas" />
    </div>
  </div>
</template>
<style scope>
:root {
  --dark-theme-bg: rgb(26 34 44 / var(--tw-bg-opacity, 1));
  --in-range-bg: rgb(251 133 0 / var(--tw-text-opacity, 1));
}


.dark .reportrange-text,
.dark .calendars,
.dark .calendar-table,
.dark .calendar-table th,
.dark .calendar-table tr,
.dark .calendar-table td {
  background-color: var(--dark-theme-bg);
  color: white;
}

.dark .calendar-table {
  border: none;
}

.dark .calendar-table .prev span,
.dark .calendar-table .next span{
  border: solid white;
  border-width: 0 2px 2px 0;

}

.dark .reportrange-text {
  background: none;
}

.dark .calendar-table td.in-range {
  background-color: var(--in-range-bg);
}
</style>
