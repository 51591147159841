<script setup lang="ts">
import { onMounted } from 'vue'
import { v4 as uuid } from 'uuid'
import ApexCharts from 'apexcharts'

import { hidePreloader } from '@services/preloader'

type LineChartOverTimeOptions = {
  yMax?: number
}

type ChartDataset = {
  name: string
  data: Array<number>
} & LineChartOverTimeOptions

const props = defineProps<{
  elementId?: string
  class?: string
  labels: Array<string>
  dataset: ChartDataset
  options?: LineChartOverTimeOptions
}>()



const elementId = props.elementId ?? uuid()
const classes = props.class ?? ''

const chart01 = () => {
  const chartOneOptions = {
    series: [
      {
        name: props.dataset.name,
        data: props.dataset.data,
      },

      // {
      //   name: "Product Two",
      //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 51],
      // },
    ],
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#FB8500", "#80CAEE"],
    chart: {
      fontFamily: "Satoshi, sans-serif",
      height: 335,
      type: "area",
      dropShadow: {
        enabled: true,
        color: "#FFB703",
        top: 10,
        blur: 4,
        left: 0,
        opacity: 0.1,
      },

      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 300,
          },
        },
      },
      {
        breakpoint: 1366,
        options: {
          chart: {
            height: 350,
          },
        },
      },
    ],
    stroke: {
      width: [2, 2],
      curve: "straight",
    },

    labels: {
      show: false,
      position: "top",
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
      colors: "#fff",
      strokeColors: ["#FB8500", "#023047"],
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      hover: {
        size: undefined,
        sizeOffset: 5,
      },
    },
    xaxis: {
      type: "category",
      categories: props.labels,
      // [
      //   "Sep",
      //   "Oct",
      //   "Nov",
      //   "Dec",
      //   "Jan",
      //   "Feb",
      //   "Mar",
      //   "Apr",
      //   "May",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      // ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: "0px",
        },
      },
      min: 0,
      max: props.options?.yMax,
      labels: {
        formatter: function (value: number) {
          return Math.round(value).toLocaleString(); // This will format the number with commas
        },
      },
    },
  };

  const chartSelector = document.getElementById(elementId);
  console.log('chartSelector', chartSelector)

  if (chartSelector) {
    const chartOne = new ApexCharts(
      chartSelector,
      chartOneOptions
    );
    chartOne.render()
    setTimeout(hidePreloader, 2000)
  }
};

onMounted(() => {
  chart01()
})

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// )

// type LineChartOverTimeOptions = {
//   yMax?: number
// }

// const props = defineProps<{
//   labels: Array<string>
//   data: Array<number>
//   options: LineChartOverTimeOptions
// }>()

// const datasets = [{
//   label: false,
//   data: props.data,
//   fill: false,
//   borderColor: 'rgb(75, 192, 192)',
//   tension: 0.1,
// }] as any // TODO: FIX THIS

// const options = {
//   scales: {
//     x: {
//       // display: false,
//       // title: {
//       //   display: false,
//       // },
//     },
//     y: {
      
//       min: props.options?.yMin ?? undefined,
//       max: props.options?.yMax ?? 100,
//     },
//   },
//   plugins: {
//     legend: {
//       display: false,
//     },
//   },
// }

</script>

<template>
  <div :id="elementId" :class="classes"></div>
</template>