import http from '@http/apiProvider'

export type IAccountDetailsParams = {
  firstName: string
  lastName: string
  email: string
}

export type IUpdateAccountDetailsResponse = {
  status: string
}

export type ICreateUserResponse = {
  id: number
  fullname: string
  email: string
  role: string
}

export type IUpdatePasswordParams = {
  currentPassword: string
  newPassword: string
}

export type IUpdatePasswordResponse = {
  status: string
  message: string
  errors: Array<string>
}


export async function updateAccountDetails(params: IAccountDetailsParams) {
  const response = await http.put(`/users/account_details.json`, { params })
  return response.data as IUpdateAccountDetailsResponse
}

export async function changePasswordAPI(params: IUpdatePasswordParams) {
  const response = await http.put(`/users/change_password.json`, { params })
  return response.data as IUpdatePasswordResponse
}

export async function createUser(params: IAccountDetailsParams) {
  const response = await http.post(`/users`, { params })
  return response.data.data as ICreateUserResponse
}