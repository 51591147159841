import { DateTime } from 'luxon'
import BaseModel from '@models/Model'

export interface IOutboundEmail {
  id: number
  type: OutboundEmailType
  provider: string
  to: string
  from: string
  subject?: string
  bodyHtml?: string
  bodyText?: string
  postmarkTemplate?: string
  postmarkTemplateData?: IIndexable
  sentAt?: string
  deliveredAt?: string
  openedAt?: string
  clickedAt?: string
}

type OutboundEmailType = 'OutboundEmail::FacebookUpdateEmail'

const OutboundEmailTypeFormatted = {
  'OutboundEmail::FacebookUpdateEmail': 'Monthly Update Email',
}

class OutboundEmail extends BaseModel {
  NAME = 'OutboundEmail'

  id!: number
  emailType!: OutboundEmailType
  provider!: string
  to!: string
  from!: string
  subject?: string
  bodyHtml?: string
  bodyText?: string
  postmarkTemplate?: string
  postmarkTemplateData?: IIndexable
  sentAt?: string
  sentAtDt?: DateTime
  deliveredAt?: string
  deliveredAtDt?: DateTime
  openedAt?: string
  openedAtDt?: DateTime
  clickedAt?: string
  clickedAtDt?: DateTime

  constructor (data: IOutboundEmail) {
    Object.keys(data).forEach(key => { (data as IIndexable)[key] = (data as IIndexable)[key] === null ? undefined : (data as IIndexable)[key] })
    super(data)
    Object.assign(this, data)
    if (this.sentAt !== undefined) {
      this.sentAtDt = DateTime.fromISO(this.sentAt)
    }
    if (this.deliveredAt !== undefined) {
      this.deliveredAtDt = DateTime.fromISO(this.deliveredAt)
    }
    if (this.openedAt !== undefined) {
      this.openedAtDt = DateTime.fromISO(this.openedAt)
    }
    if (this.clickedAt !== undefined) {
      this.clickedAtDt = DateTime.fromISO(this.clickedAt)
    }
  }

  get fromPostmark () { return this.postmarkTemplate !== undefined }
  get sent () { return this.sentAt !== undefined }
  get delivered () { return this.deliveredAt !== undefined }
  get opened () { return this.openedAt !== undefined }
  get clicked () { return this.clickedAt !== undefined }

  get typeFormatted () {
    return OutboundEmailTypeFormatted[this.emailType]
  }

  get sentAtFormatted () { return this.sentAtDt?.toFormat('LLL dd, yy @ h:mma') ?? '-' }
  get deliveredAtFormatted () { return this.deliveredAtDt?.toFormat('LLL dd, yy @ h:mma') ?? '-' }
  get openedAtFormatted () { return this.openedAtDt?.toFormat('LLL dd, yy @ h:mma') ?? '-' }
  get clickedAtFormatted () { return this.clickedAtDt?.toFormat('LLL dd, yy @ h:mma') ?? '-' }
}

export default OutboundEmail