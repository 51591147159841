import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  declare readonly dropMenuTarget: HTMLElement
  toggleTransition!: (event?: Event) => void
  leave!: (event?: Event) => void
  transitioned!: false

  static targets = ['dropMenu']

  connect (): void {
    useTransition(this, {
      element: this.dropMenuTarget,
    })
  }

  toggle (evt: any): void {
    evt.preventDefault()
    this.toggleTransition()
  }

  hide (event: Event): void {
    // @ts-ignore
    if (!this.element.contains(event.target) && !this.dropMenuTarget.classList.contains('hidden')) {
      this.leave()
    }
  }
}