import http from '@http/apiProvider'
import OutboundEmail from '@models/OutboundEmail'

import type { IOutboundEmail } from '@models/OutboundEmail'

export type IQueryOutboundEmailsParams = {
  scopeType: string
  scopeId: number
} 

export type IQueryOutboundEmailsResponse = Array<OutboundEmail>

export async function queryOutboundEmails (params: IQueryOutboundEmailsParams) {
  const response = await http.get(`/outbound_emails.json`, { params })
  return response.data.data.map((d: IOutboundEmail) => new OutboundEmail(d)) as IQueryOutboundEmailsResponse
}