<script setup lang="ts">
import { ref, reactive, computed } from 'vue'
import { DateTime } from 'luxon'

import api from '@http/apiProvider'

const props = defineProps<{
  facebookAdClientId: number
  initialDate?: string
}>()

type FacebookResults = Array<{
  namedArea: string
  impressions: number
  clicks: number
  cpc: number
  cpm: number
  spend: number
  inlineLinkClicks: number
}>

let results = ref<FacebookResults>([])
const date = ref<DateTime>(
  props.initialDate !== undefined
    ? DateTime.fromFormat(props.initialDate, '%yyyy-%MM-%dd')
    : DateTime.now()
)

// Load initial data
async function requestData () {
  results.value = (await api.get('/facebook_ad_results', {
    params: {
      facebookAdClientId: props.facebookAdClientId,
      date: date.value.toISODate(),
    },
  // @ts-ignore - TODO: Fix this
  })).data.map(r => ({
    ...r,
    cpc: parseFloat(r.cpc),
    cpm: parseFloat(r.cpm),
    spend: parseFloat(r.spend),
  }) as FacebookResults)
}
requestData()

function prevDay () {
  date.value = date.value.minus({ days: 1 })
  requestData()
}

function nextDay () {
  date.value = date.value.plus({ days: 1 })
  requestData()
}

function sum (total: number, v: number, idx: number, arr: Array<number>) {
  total += v
  return total
}

const totals = {
  clicks: computed(() => results.value.map(v => v.clicks).reduce(sum, 0)),
  inlineLinkClicks: computed(() => results.value.map(v => v.inlineLinkClicks).reduce(sum, 0)),
  spend: computed(() => results.value.map(v => v.spend).reduce(sum, 0)),
}
</script>

<template>
  <div class="w-full flex justify-center">
    <span class="isolate inline-flex rounded-md shadow-sm">
      <button @click="prevDay" type="button" class="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
        <span class="sr-only">Previous</span>
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
        </svg>
      </button>
      <span class="w-44 justify-center relative -mx-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300">{{ date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) }}</span>
      <button @click="nextDay" type="button" class="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
        <span class="sr-only">Next</span>
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
        </svg>
      </button>
    </span>
  </div>
  <table class="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">Named Area</th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Impressions</th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Clicks (All)</th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Clicks to Website</th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Clicks to FB Page</th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CPC</th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CPM</th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Spend</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="res in results" class="even:bg-gray-100">
            <td class="whitespace-nowrap pl-4 py-1 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">{{ res.namedArea }}</td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ res.impressions }}</td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ res.clicks }}</td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ res.inlineLinkClicks }}</td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ res.clicks - res.inlineLinkClicks }}</td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ res.cpc || '-' }}</td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ res.cpm || '-' }}</td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ res.spend || '-' }}</td>
          </tr>
          <tr class="even:bg-gray-100 font-bold">
            <td class="whitespace-nowrap pl-4 py-1 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"></td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500"></td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ totals.clicks }}</td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ totals.inlineLinkClicks }}</td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500"></td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500"></td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500"></td>
            <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{{ totals.spend }}</td>
          </tr>
      </tbody>
    </table>
</template>
