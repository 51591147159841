import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggle (evt: any): void {
    evt.preventDefault()
    document.documentElement.classList.toggle('dark')
    if (document.documentElement.classList.contains('dark')) {
      localStorage.setItem('darkMode', 'true')
    } else {
      localStorage.setItem('darkMode', 'false')
    }
  }
}