import http from '@http/apiProvider'

export type IQueryFacebookStatsForDashboardParams = {
  facebookAdClientId: number
  startDate: string
  endDate: string
}

type IChartNamedAreaDataset = {
  name: string,
  impressions: number,
  reach: number,
  spend: number,
  cpc: number,
  cpm: number,
}

export type IChartNamedAreasDataset = {
  name: string
  data: Array<IChartNamedAreaDataset>
  max?: number
  total: number
}

type IChartDataset = {
  name: string
  data: Array<number>
  max?: number
  total: number
}

export type IQueryFacebookStatsForDashboardResponse = {
  totalImpressions: number
  totalClicks: number
  totalClicksToWebsite: number
  totalSpend: number
  totalCpc: number
  labels: Array<string>
  datasets: {
    impressions: IChartDataset
    spend: IChartDataset
    clicks: IChartDataset
    clicksToWebsite: IChartDataset
    namedAreas: IChartNamedAreasDataset
  }
}
export async function queryFacebookStatsForDashboard(params: IQueryFacebookStatsForDashboardParams) {
  const response = await http.get(`/facebook/stats.json`, { params })
  return response.data as IQueryFacebookStatsForDashboardResponse
}