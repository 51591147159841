import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  declare messageValue: string

  static values = { message: String }

  confirm (event: Event) {
    console.log('EVENT', event, this, this.messageValue)
    if (!(window.confirm(this.messageValue))) {
      event.preventDefault()
    }
  }
}