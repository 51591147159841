import { v4 as uuid } from 'uuid'
import { loadStripe } from '@stripe/stripe-js'

import StripePaymentMethods from "@services/StripePaymentMethods"
import { RailsVars } from '@utils'

import type { Stripe, StripeCardElement, CreatePaymentMethodData, PaymentMethod } from "@stripe/stripe-js"

export class StripeClient {
  uuid: string = uuid()
  client: Stripe | undefined

  // Stripe functionality modules
  PaymentMethods = new StripePaymentMethods(this)

  get isInitialized () {
    return this.client !== undefined
  }

  public async initialize () {
    if (!this.client) {
      this.client = (await loadStripe(RailsVars.stripePublishKey)) || undefined
    }
  }

  public requireInitialization (): asserts this is { client: Stripe } {
    if (!this.client) {
      throw new Error('Stripe client is not initialized')
    }
  }
}

const stripeClient = new StripeClient()
export default stripeClient