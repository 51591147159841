<template>
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[100000]">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition-group name="slide-fade">
        <toast v-for="t in allToasts" :key="t.id" v-bind="t" />
      </transition-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toast } from '@frontend/toasts/ToastService'
import Toast from '@frontend/toasts/Toast.vue'
const allToasts = toast.activeToasts
</script>