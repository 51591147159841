import { Controller } from '@hotwired/stimulus'

import { isJSONContent, JSONEditor, toJSONContent } from 'vanilla-jsoneditor'
// import 'jsoneditor/dist/jsoneditor.min.css'
// import 'jsoneditor/dist/img/jsoneditor-icons.svg'
// import 'vanila-jsoneditor/themes/jse-theme-light.css'

export default class extends Controller {
  declare readonly containerTarget: HTMLElement
  declare readonly hiddenElementTarget: HTMLInputElement
  initialJson!: {}
  editor!: JSONEditor

  static targets = ['container', 'hiddenElement']

  setJson (data: any): void {
    this.editor.set({ json: data })
  }

  connect (): void {
    this.initialJson = JSON.parse(this.data.get('initialJson')!)
    this.editor = new JSONEditor({
      target: this.containerTarget,
      props: {
        content: { json: this.initialJson },
        onChange: (updatedContent) => {
          if (isJSONContent(updatedContent)) {
            this.hiddenElementTarget.value = JSON.stringify(updatedContent.json)
          } else {
            this.hiddenElementTarget.value = JSON.stringify(toJSONContent(updatedContent).json)
          }
        },
      },
    })

    let initialContent = this.editor.get()
    let jsonValue = undefined
    if (isJSONContent(initialContent)) {
      jsonValue = initialContent.json
    } else {
      jsonValue = toJSONContent(initialContent).json
    }

    this.hiddenElementTarget.value = JSON.stringify(jsonValue)
  }
}