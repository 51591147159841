<script setup lang="ts">
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

type LineChartPreviewOptions = {
  yMax?: number
}

const props = defineProps<{
  labels: Array<string>
  data: Array<number>
  options: LineChartPreviewOptions
}>()

const datasets = [{
  label: false,
  data: props.data,
  fill: false,
  borderColor: 'rgb(75, 192, 192)',
  tension: 0.1,
}] as any // TODO: FIX THIS

const options = {
  scales: {
    x: {
      display: false,
      // title: {
      //   display: false,
      // },
    },
    y: {
      max: props.options?.yMax ?? undefined,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}

</script>

<template>
  <Line :data="{ labels, datasets }" :options="options" />
</template>